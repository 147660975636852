@tailwind base;
@tailwind components;
@tailwind utilities;
@property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
}
@import "flowbite";
@layer utilities {
    .font-semi-lg{
        @apply font-semibold text-lg
    }
    .font-semi-xl{
        @apply font-semibold text-xl
    }
    .font-semi-4xl{
        @apply font-semibold text-4xl
    }
  }

.scrollbar-hide::-webkit-scrollbar {
    display: none; /* Ẩn scrollbar trong trình duyệt WebKit (Chrome, Safari, v.v.) */
}

.scrollbar-hide {
    -ms-overflow-style: none; /* Ẩn scrollbar cho Internet Explorer và Edge */
    scrollbar-width: none; /* Ẩn scrollbar cho Firefox */
}
